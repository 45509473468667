<template>
  <div class="animated fadeIn">
    <b-card>
      <h4 class="d-inline"><i class="nav-icon icon-layers"></i> Invoice Parameter</h4>
      <div class="row">
        <div class="col-md-6 mt-3">
          <multiselect v-model="parameter" @input="setParameter(), value_1 = '', value_2 = '', value_3 = '', value_4 = ''" placeholder="Search" tag-placeholder="Search" track-by="name" :options="parameterList" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="title">
          </multiselect>
          <h6 class="mt-3" v-if="parameter.is_remote && dynamicParameter.remote_timestamp">(Synced on: {{dynamicParameter.processed_at | moment('MM/DD/YYYY HH:MM')}})</h6>
        </div>

        <div class="col-md-6 mt-3" v-if="!parameter.is_remote">
          <!-- <button v-b-modal.new class="btn btn-primary">New <i class="fa fa-plus"></i></button> -->
          <vue-json-to-csv :labels="labels" :csv-title="'Invoice Parameters'" class="btn btn-blue"
            :json-data='[{"value_1": "Example", "value_2": "Example", "value_3": "Example", "value_4": "Example"}]'>
            Download CSV
            <i class="fa fa-download"></i>
          </vue-json-to-csv>

          <button v-b-modal.import class="btn btn-success ml-2">Import CSV <i class="fa fa-file"></i></button>
          <button class="btn btn-danger ml-2" @click="deleteAll()">Delete All Unused <i class="fa fa-times"></i></button>
        </div>

        <b-modal hide-footer id="import" ref="import" title="Import From CSV" title-tag="h2" centered size="lg">
          <div class="parse mt-2 mb-4 row">
            <div class="col-md-6">
              <h5>CSV Files Only</h5>
              <input
                class="mt-2"
                id="fileInput"
                type="file"
                ref="csvFile"
                @change="upload">
            </div>
            <div v-if="doc.length > 0" class="col-md-6">
              <h5>Import</h5>
              <button @click="importRows(doc)" class="btn btn-primary">Import</button>
            </div>

            <div class="col-md-12 mt-4" v-if="success.length > 0">
              <h5>Successfully Imported: </h5>
              <ul>
                <li v-for="success in success">
                  {{success}}
                </li>
              </ul>
            </div>
            <div class="col-md-12 mt-4" v-if="errorList.length > 0">
              <h5>Unsuccessful: </h5>
              <ul>
                <li v-for="error in errorList">
                  {{error}}
                </li>
              </ul>
            </div>
          </div>
        </b-modal>

        <!-- <b-modal hide-footer id="new" ref="new" title="Add New Complex Field" title-tag="h2" centered size="lg">
          <div class="row mb-4">
            <div class="col-md-7">
              <label>Field Name</label>
              <input type="text" class="form-control" v-model="field" placeholder="Field Name">
            </div>
            <div class="col-md-5">
              <label for="">&nbsp;</label>
              <b-button variant="primary" @click="addNewComplexField()">
                Add New Field <i class="fa fa-plus"></i>
              </b-button>
            </div>
          </div>
        </b-modal> -->

        <div class="col-md-12" v-if="!parameter.is_remote && parameter != ''">
          <h4 class="pt-3">Add New</h4>
          <table style="background: #f6f6f6" class="table all-col" :class="{hideActions: parameter.is_remote}" :columns="columns">
            <thead>
              <th>{{options.headings['value_1']}}</th>
              <!-- <th>{{options.headings['value_2']}}</th>
              <th>{{options.headings['value_3']}}</th>
              <th>{{options.headings['value_4']}}</th> -->
              <th>Actions</th>
            </thead>
            <tbody style="border-top: 8px solid #c8ced3;">
              <tr>
                <td>
                  <vue-monthly-picker placeHolder="Select Date" v-model="value_1" v-if="parameter.name == 'activity_period'"></vue-monthly-picker>
                  <input v-else type="text" class="form-control" v-model="value_1" placeholder="Name">
                </td>
                <!-- <td><input type="text" class="form-control" v-model="value_2" placeholder="Major"></td>
                <td><input type="text" class="form-control" v-model="value_3" placeholder="Minor"></td>
                <td><input type="text" class="form-control" v-model="value_4" placeholder="Description"></td>
                <td><input type="checkbox" style="margin-top: 10px" v-model="is_default"></td> -->
                <td><b-button class="btn-sm"  variant="success" @click="addToComplexValues()">
                  <i class="fa fa-plus"></i>
                </b-button></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-12 mt-2" v-if="parameter">
          <v-client-table id="dataTable" class="all-col" :class="{hideHeaders: parameter.name == 'activity_period', hideActions: parameter.is_remote}" :columns="columns" :data="parameter.values" ref="invoicetable" :options="options" theme="bootstrap4">

            <div slot="value_1" slot-scope="props">
              <div v-if="editIndex != props.index - 1">
                <span v-if="parameter.name != 'activity_period'">{{props.row.value_1}}</span>
                <span v-else-if="parameter.name == 'activity_period'">{{$moment(props.row.value_1).format("YYYY/MM")}}</span>
                <span v-else>{{$moment(props.row.value_1).format("YYYY/MM")}}</span>
              </div>
              <div v-if="editIndex == props.index - 1">
                <vue-monthly-picker placeHolder="Select Date" v-model="edit.value_1" v-if="parameter.name == 'activity_period'"></vue-monthly-picker>
                <input type="text" class="form-control" v-model="edit.value_1" placeholder="Name" v-else>
              </div>
            </div>
            <div slot="value_2" slot-scope="props">
              <div>
                <span v-if="editIndex != props.index - 1 && parameter.name == 'accounting_period'">{{$moment(props.row.value_2).format("YYYY/MM")}}</span>
                <span v-if="editIndex != props.index - 1 && parameter.name != 'accounting_period'">{{props.row.value_2}}</span>
              </div>
            </div>
            <div slot="value_3" slot-scope="props">
              <div>
                <span v-if="editIndex != props.index - 1">{{props.row.value_3}}</span>
              </div>
            </div>
            <div slot="value_4" slot-scope="props">
              <div>
                <span v-if="editIndex != props.index - 1">{{props.row.value_4}}</span>
              </div>
            </div>
            <div slot="is_default" slot-scope="props">
              <input v-if="parameter.name != 'business_associate' && editIndex != props.index - 1 && 'products'" type="checkbox" v-model="props.row.is_default" @change="setDefault(props.row.id, props.index - 1)">
            </div>
            <div slot="actions" slot-scope="props">
              <b-button v-if="editIndex != props.index - 1 && parameter.is_remote == false" variant="primary" class="btn-sm mr-2" @click="editRow(props.row, props.index - 1)">
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button v-if="editIndex != props.index - 1 && parameter.is_remote == false" variant="danger" class="btn-sm" @click="deleteComplexValues(props.row.id, props.index - 1, props.row.is_default, true)">
                <i class="fa fa-times"></i>
              </b-button>
              <b-button v-if="editIndex == props.index - 1" variant="success" class="btn-sm" @click="editComplexValues(props.row.id, props.index - 1, props.row.is_default, props.row.qicf_id)">
                <i class="fa fa-check"></i>
              </b-button>
            </div>
          </v-client-table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueJsonToCsv from 'vue-json-to-csv'
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import Papa from 'papaparse';
import Blob from 'blob'
import FileSaver from 'file-saver'

import VueMonthlyPicker from 'vue-monthly-picker'
Vue.component('my-component', {
  components: {
      VueMonthlyPicker
  }
});

import {ClientTable} from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'manager',
  components: {
    VueJsonToCsv,
    Multiselect,
    RotateSquare2,
    VueMonthlyPicker,
    ClientTable
  },
  watch: {
    parameter: function (val) {
      //"afe", "org", "invoice_type", "accounting_period", "activity_period", "workpath", "account", "pay_code", "cost_center", "business_associate", "purchase_num"

      if(this.parameter.name == "afe"){
        this.options.headings = {
          value_1: 'Number',
          value_2: 'Name',
          value_3: '',
          value_4: '',
          is_default: '',
        }
      }

      if(this.parameter.name == "org"){
        this.options.headings = {
          value_1: 'Organization ID',
          value_2: 'Name',
          value_3: 'Organization Type',
          value_4: '',
          is_default: 'Default',
        }
      }

      if(this.parameter.name == "accounting_period"){
        this.options.headings = {
          value_1: 'Organization ID',
          value_2: 'Period',
          value_3: 'System Code',
          value_4: '',
          is_default: 'Default',
        }
      }

      if(this.parameter.name == "account"){
        this.options.headings = {
          value_1: 'Major',
          value_2: 'Minor',
          value_3: 'Description',
          value_4: '',
          is_default: '',
        }
      }

      if(this.parameter.name == "pay_code"){
        this.options.headings = {
          value_1: 'Code',
          value_2: 'Description',
          value_3: '',
          value_4: '',
          is_default: 'Default',
        }
      }

      if(this.parameter.name == "cost_center"){
        this.options.headings = {
          value_1: 'Number',
          value_2: 'Name',
          value_3: '',
          value_4: '',
          is_default: '',
        }
      }

      if(this.parameter.name == "business_associate"){
        this.options.headings = {
          value_1: 'Legal Name',
          value_2: 'ID',
          value_3: 'Registration Number',
          value_4: 'Name',
          is_default: '',
        }
      }

      if(this.parameter.name == "invoice_type"){
        this.options.headings = {
          value_1: 'Name',
          value_2: 'Major',
          value_3: 'Minor',
          value_4: 'Description',
          is_default: 'Default',
        }
      }

      if(this.parameter.name == "purchase_num"){
        this.options.headings = {
          value_1: 'Number',
          value_2: 'Type Code',
          value_3: 'Description',
          value_4: '',
          is_default: 'Default',
        }
      }

      if(this.parameter.name == "activity_period"){
        this.options.headings = {
          value_1: 'Period',
          value_2: '',
          value_3: '',
          value_4: '',
          is_default: 'Default',
        }
      }

        if(this.parameter.name == "products"){
        this.options.headings = {
          value_1: 'PROD CODE',
          value_2: 'PROD NAME',
          value_3: 'SI UNIT',
          value_4: '',
          is_default: '',
        }
      }
    },
  },
  data () {
    return {
      success: [],
      errorList: [],
      doc: [],
      parameterList: [],
      parameter: "",
      dynamicParameter: {},
      name: "",
      field: "",
      value_1: "",
      value_2: "",
      value_3: "",
      value_4: "",
      is_default: false,
      editIndex: null,
      edit: {
        value_1: "",
        value_2: "",
        value_3: "",
        value_4: "",
      },
      labels: {
        value_1: { title: 'Name'},
        value_2: { title: 'Minor'},
        value_3: { title: 'Major'},
        value_4: { title: 'Description'},
      },
      columns: ['value_1','value_2','value_3','value_4', 'is_default', 'actions'],
      options: {
        headings: {
          value_1: 'Name',
          value_2: 'Major',
          value_3: 'Minor',
          value_4: 'Description',
          is_default: 'Default',
        },
        sortable: ['value_1','value_2','value_3','value_4', 'is_default'],
        filterable: ['value_1','value_2','value_3','value_4', 'is_default'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 10,
        texts:{
          filterPlaceholder: "Search By Name"
        },
        // pagination: {
        // 	nav: 'scroll',
        //   chunk: 40
        // },
      },
    }
  },
  methods: {
    editTheIndex(index) {
      if(this.parameter.name == 'activity_period'){
        this.editIndex = index;
      }
    },
    setParameter () {
      if(this.parameter.is_remote){
        this.$http.get('/accountant/qbyte/complex_fields/' + this.parameter.id).then(response => {
          this.dynamicParameter = response.data.last_successful_sync;
        })
      }
    },
    upload (e) {
      const that = this
      const fileToLoad = e.target.files[0]

      const reader = new FileReader()
      reader.onload = fileLoadedEvent => {
        Papa.parse(fileLoadedEvent.target.result, {
          header: true,
          complete (results) {
            that.doc = results.data;
          },
          error (errors) {
          }
        })
      }
      reader.readAsText(fileToLoad)
    },
    importRows(doc) {
      var data = [];

      for (var i = 0; i < doc.length; i++) {
        var duplicates = this.parameter.values.filter(x=>
          x.value_1 == doc[i].Name &&
          x.value_2 == doc[i].Major &&
          x.value_3 == doc[i].Minor &&
          x.value_4 == doc[i].Description);

        var value_1 = doc[i].name;

        if(this.parameter.name == 'activity_period'){
          value_1 = this.$moment(value_1).endOf('month').format('YYYY-MM-DD');
        }

        if(duplicates.length == 0){
          data.push({
            "value_1": value_1,
            "value_2": doc[i].Major,
            "value_3": doc[i].Minor,
            "value_4": doc[i].Description,
            "is_default": false
          })
        }
      }

      if(this.parameter.values.length == 0){
        data[0].is_default = true;
      }

      console.log(data);

      if(data.length > 0){
        this.$http.post('/accountant/qbyte/complex_fields/' + this.parameter.id + '/values', data).then(response => {
          for (var i = 0; i < response.data.length; i++) {
            this.parameter.values.push({
              "value_1": response.data[i].value_1,
              "value_2": response.data[i].value_2,
              "value_3": response.data[i].value_3,
              "value_4": response.data[i].value_4,
              "is_default": response.data[i].is_default,
              "id": response.data[i].id
            })
          }
        })
      }

      this.$refs.import.hide();
      this.$toasted.show('Imported ' + data.length + ' Successfully', {type: 'success', duration: '3000'});
    },
    editRow(row, index){
      if(this.editIndex > 0){
        this.$toasted.show('Please finish editing row', {type: 'danger', duration: '3000'});
        return
      }
      this.edit = {
        "value_1": row.value_1,
        "value_2": row.value_2,
        "value_3": row.value_3,
        "value_4": row.value_4,
      }
      this.editIndex = index;
    },
    editComplexValues(id, index, is_default, qicf_id){
      console.log(id, index, is_default, qicf_id)
      if(this.edit.value_1 == ""){
        this.$toasted.show('Name is empty', {type: 'error', duration: '3000'});
        return
      }

      var value_1 = this.edit.value_1;

      if(this.parameter.name == 'activity_period'){
        value_1 = this.$moment(this.edit.value_1).endOf('month').format('YYYY-MM-DD');
      }

      let data = {
        "value_1": value_1,
        "is_default": is_default,
      }

      if(this.edit.value_2 != null){
        data.value_2 = this.edit.value_2;
      }
      if(this.edit.value_3 != null){
        data.value_3 = this.edit.value_3;
      }
      if(this.edit.value_4 != null){
        data.value_4 = this.edit.value_4;
      }

      this.$http.put('/accountant/qbyte/complex_fields/' + this.parameter.id + '/values/' + id, data).then(response => {
        var temp = this.parameter.values;

        temp[index].value_1 = response.data.value_1;
        temp[index].value_2 = response.data.value_2;
        temp[index].value_3 = response.data.value_3;
        temp[index].value_4 = response.data.value_4;

        this.parameter.values = temp;

        this.editIndex = null;

        this.edit = {
          value_1: "",
          value_2: "",
          value_3: "",
          value_4: "",
        }
        this.$toasted.show('Field Edited Successfully', {type: 'success', duration: '3000'});
      }).catch(error => {
        this.$toasted.show('Unsuccessful.', {type: 'error', duration: '3000'});
      })
    },
    // addNewComplexField(){
    //   // this.$http.post('​/accountant​/qbyte​/complex_fields​/44​/values').then(response => {
    //   //
    //   // })
    //
    //   this.parameterList.push({
    //     "data_type": "string",
    //     "id": 33,
    //     "name": this.field,
    //     "values": [],
    //   })
    //
    //   this.parameter = {
    //     "name": this.field
    //   }
    //
    //   this.$refs.new.hide();
    //
    //   this.$toasted.show('New Field Added Successfully', {type: 'success', duration: '3000'});
    //
    // },
    addToComplexValues(){
      if(this.value_1 == ""){
        this.$toasted.show('Name is empty', {type: 'error', duration: '3000'});
        return
      }
      var defaultSetting = this.is_default;

      if(this.parameter.values.length == 0){
        defaultSetting = true;
      }

      var value_1 = this.value_1;

      if(this.parameter.name == 'activity_period'){
        value_1 = this.$moment(this.value_1).endOf('month').format('YYYY-MM-DD');
      }

      this.$http.post('/accountant/qbyte/complex_fields/' + this.parameter.id + '/values', [{
        "value_1": value_1,
        "value_2": this.value_2,
        "value_3": this.value_3,
        "value_4": this.value_4,
        "is_default": defaultSetting
      }]).then(response => {
        for (var i = 0; i < response.data.length; i++) {

          this.parameter.values.push({
            "value_1": response.data[i].value_1,
            "value_2": response.data[i].value_2,
            "value_3": response.data[i].value_3,
            "value_4": response.data[i].value_4,
            "is_default": response.data[i].is_default,
            "id": response.data[i].id
          })
        }

        if(this.is_default){
          for (var i = 0; i < this.parameter.values.length; i++) {
            this.parameter.values[i].is_default = false;
          }

          this.parameter.values[this.parameter.values.length-1].is_default = true;
        }

        this.value_1 = "";
        this.value_2 = "";
        this.value_3 = "";
        this.value_4 = "";
        this.is_default = false;

        this.$toasted.show('New Value Added Successfully', {type: 'success', duration: '3000'});
      })
    },
    deleteComplexValues(id, index, is_default, max){
      this.$http.delete('/accountant/qbyte/complex_fields/' + this.parameter.id + '/values/' + id).then(response => {
        this.parameter.values.splice(index, 1);

        if(is_default && this.parameter.values.length > 0){
          this.setDefault(this.parameter.values[0].id, 0);
        }
        if(max){
          this.$toasted.show('Deleted Successfully', {type: 'success', duration: '3000'});
        }
      }).catch(error => {
        if(max){
          this.$toasted.show('Unsuccessful. In Use.', {type: 'error', duration: '3000'});
        }
      })
    },
    deleteAll(){
      this.$http.delete('/accountant/qbyte/complex_fields/' + this.parameter.id + '/values/').then(response => {
        this.$toasted.show('Deleted All Successfully', {type: 'success', duration: '3000'});

        this.$http.get('/accountant/qbyte/complex_fields').then(response => {
          this.parameterList = response.data;

          var findIndex = this.parameterList.findIndex(x=>x.name == this.parameter.name)
          this.parameter = this.parameterList[findIndex];
        })
      }).catch(error => {
      })
    },
    setDefault(id, index){
      this.$http.patch('/accountant/qbyte/complex_fields/' + this.parameter.id + '/set_default', {
        "value_id": id,
      }).then(response => {
        for (var i = 0; i < this.parameter.values.length; i++) {
          this.parameter.values[i].is_default = false;
        }

        this.parameter.values[index].is_default = true;
        this.$toasted.show('Default Set', {type: 'success', duration: '3000'});
      })

    }
  },
  mounted() {
    this.$http.get('/accountant/qbyte/complex_fields').then(response => {
      this.parameterList = response.data;
    })
  }
}
</script>

<style lang="scss">
  .all-col {
    .table-responsive,
    .table-responsive table {
      overflow: initial !important;
    }

    thead {
      th:nth-child(5),
      th:nth-child(6) {
        width: 10%;
      }

      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4) {
        width: 20%;
      }
    }
    tbody {
      tr:nth-child(5),
      tr:nth-child(6) {
        width: 10%;
      }

      tr:nth-child(1),
      tr:nth-child(2),
      tr:nth-child(3),
      tr:nth-child(4) {
        width: 20%;
      }
    }
  }



  // .hideHeaders {
  //   thead {
  //     th:first-child {
  //       width: 70%;
  //     }
  //
  //     th:nth-child(5),
  //     th:nth-child(6) {
  //       width: 15%;
  //     }
  //
  //     th:nth-child(2),
  //     th:nth-child(3),
  //     th:nth-child(4) {
  //       display: none !important;
  //     }
  //   }
  //   tbody {
  //     tr:first-child {
  //       width: 70%;
  //     }
  //
  //     tr:nth-child(5),
  //     tr:nth-child(6) {
  //       width: 15%;
  //     }
  //
  //     tr td:nth-child(2),
  //     tr td:nth-child(3),
  //     tr td:nth-child(4) {
  //       display: none !important;
  //     }
  //   }
  // }



  .hideActions {
    thead {
      th:last-child {
        display: none !important;
      }
    }
    tbody {
      tr td:last-child {
        display: none !important;
      }
    }
  }

  .parameters {
    tbody tr td:nth-child(2),
    thead th:nth-child(2),
    tbody tr td:nth-child(3),
    thead th:nth-child(3) {
      width: 15%;
      display: table-cell !important;
    }
  }

  .parameters td {
    vertical-align: middle;
  }
</style>
